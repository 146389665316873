import React from 'react';

const DaySoldOut = ({ day }) => (
  <button
    disabled={true}
    className="day block rounded-lg mx-auto bg-white2 text-green py-1 px-6 cursor-default"
  >
    <span className="line-through">{day.label}</span> SOLD OUT
  </button>
);

export default DaySoldOut;
