import React from 'react';
import { useForm } from "react-hook-form";

const OrderSummary = ({ order, price, description, currentStep, setCurrentStep }) => {
  const { handleSubmit } = useForm();
  const markPaid = () => setCurrentStep('paid');

  return (
    <div className="p-6 max-w-m mx-auto font-bold text-gray">
      <div className="font-normal text-blue-dark pt-4">Order Status:</div>
      <div>{order.waitlist ? 'Waitlist' : 'Reserved'}</div>

      { order.dayConfirmed?.label && (
        <div>
          <div className="font-normal text-blue-dark pt-4">Pickup Day:</div>
          <div>{order.dayConfirmed.label}, 3pm</div>
        </div>
      )}

      <div className="font-normal text-blue-dark pt-4">Instagram:</div>
      <div className="font-normal text-blue-dark text-sm">(We will contact you via DM at this account)</div>
      <div>{order.ig}</div>

      <div className="font-normal text-blue-dark pt-4">Pickup Name:</div>
      <div className="font-normal text-blue-dark text-sm">If booking a courier make sure to book under this name</div>
      <div>{order.pickupName}</div>

      <div className="font-normal text-blue-dark pt-4">Carpool:</div>
      <div>{order.carpool ? 'Yes' : 'No'}</div>

      { order.carpoolLocation && (
        <div>
          <div className="font-normal text-blue-dark pt-4">Carpool Location:</div>
          <div>{order.carpoolLocation}</div>
        </div>
      )}

      <div className="pt-6 text-center">{description}</div>
      <div className="pt-6 text-center">{price}</div>

      <img
        src="bank.jpg"
        alt="Bank account information"
        className="max-w-sm mx-auto pt-6 object-contain"
      />

      {currentStep !== 'paid' && (
        <form onSubmit={handleSubmit(markPaid)}>
          <button
            type="submit"
            className="block py-3 px-12 rounded-lg mx-auto hover:bg-orange focus:bg-orange bg-orange3 active:bg-orange m-8 mb-2 scale-110"
          >
            Paid
          </button>
          <div className="font-normal text-gray text-sm max-w-sm text-center mx-auto">Once you have sent us proof of payment via instagram DM please click Paid to let us know.</div>
        </form>
      )}
    </div>
  );
};

export default OrderSummary;
