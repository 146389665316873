import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import DayActive from './DayActive';
import DaySoldOut from './DaySoldOut';

const Days = ({ days, selectDay, daysUpdateCheck, requestedPieCount }) => {
  const [displayDays, setDisplayDays] = useState([]);

  const renderDays = useCallback(() => {
    return (
      <div className="days space-y-6">
        {days.map(day => {
          if (day.orders?.length + requestedPieCount > day.maxSlots) {
            return <DaySoldOut day={day} key={day.label} />;
          }
          else {
            return <DayActive day={day} selectDay={selectDay} key={day.label} />;
          }
        })}
      </div>
    );
  }, [days, selectDay, requestedPieCount]);

  useEffect(() => {
    setDisplayDays(
      renderDays()
    );
  }, [renderDays, daysUpdateCheck]);

  return (
    <div>
      {displayDays}
    </div>
  );
};

export default Days;
