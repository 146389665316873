import feathers from '@feathersjs/client';
import io from 'socket.io-client';
import React, {
  useEffect,
  useState,
} from 'react';

// Components
import Days from './components/Days';
import OrderInfoForm from './components/OrderInfoForm';
import OrderSummary from './components/OrderSummary';
import Progress from './components/Progress';
import ReserveSlot from './components/ReserveSlot';

/**
 * Feathers websocket connection
 */
const socket = io(process.env.REACT_APP_API_URL, {
  transports: ['websocket'],
  forceNew: true
});
const client = feathers();
client.configure(feathers.socketio(socket));

// Feathers Services
const ordersService = client.service('orders');
const daysService = client.service('days');
const weeksService = client.service('weeks');

function App() {
  const [order, setOrder] = useState({ _id: null });
  const [weekId, setWeekId] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [weeksUpdateCheck, setWeeksUpdateCheck] = useState('');
  const [days, setDays] = useState([]);
  const [daysUpdateCheck, setDaysUpdateCheck] = useState('');
  const [daySelectionLabel, setDaySelectionLabel] = useState(false);
  const [hasWeekSlot, setHasWeekSlot] = useState(false);
  const [daySlot, setDaySlot] = useState(false);
  const [waitlistOrder, setWaitlistOrder] = useState(false);
  const [currentStep, setCurrentStepState] = useState(false);

  // Get active week. Hard coded for now.
  // This might switch to multiple active "weeks" later so handle multiple.
  useEffect(() => {
    weeksService
      .find({
        query: {
          current: true,
        }
      })
      .then(data => {
        setWeeks(data.data);

        if (data.data.length > 0) {
          setWeekId(data.data[0]._id);
        }
        else {
          setWeekId(false);
        }
      });
  }, [setWeeks, setWeekId]);

  useEffect(() => {
    weeksService.on('patched', updatedWeek => {
      const matchingIndex = weeks.findIndex(week => week._id === updatedWeek._id);
      if (matchingIndex >= 0) {
        const updatedWeeks = weeks;
        updatedWeeks[matchingIndex] = updatedWeek;
        setWeeks(updatedWeeks);
        setWeeksUpdateCheck(JSON.stringify(updatedWeeks));
      }
    });
  });

  // Get existing days
  useEffect(() => {
    daysService
      .find({
        query: {
          weekId,
        }
      })
      .then(data => {
        setDays(data.data);
      });
  }, [weekId]);

  useEffect(() => {
    daysService.on('patched', updatedDay => {
      const matchingIndex = days.findIndex(day => day._id === updatedDay._id);
      if (matchingIndex >= 0) {
        const updatedDays = days;
        updatedDays[matchingIndex] = updatedDay;
        setDays(updatedDays);
        setDaysUpdateCheck(JSON.stringify(updatedDays));
      }
    });
  });

  const setCurrentStep = newCurrentStep => {
    // Special case for paid since otherwise the render logic will override it.
    // Basically once the step is 'paid' stop updating.
    if (newCurrentStep !== currentStep && currentStep !== 'paid') {
      setCurrentStepState(newCurrentStep);
    }
  }

  function submitOrder(orderFields) {
    // Use the orders service from the server.
    ordersService.create({
      ...orderFields,
      weekId,
    })
    .then(data => {
      if (data._id) {
        setOrder(data);

        if (data?.hasWeekSlot === true) {
          setHasWeekSlot(true);
        }
      }
    });
  }

  function updateOrder(additionalOrderFields) {
    // Use the orders service from the server.
    ordersService.patch(order._id, additionalOrderFields)
    .then(data => {
      if (data._id) {
        setOrder(data);
      }
    });
  }

  const selectDay = (dayButtonId, dayButtonLabel) => {
    setDaySelectionLabel(dayButtonLabel);
    ordersService.patch(order._id, {
      daySelectionId: dayButtonId,
    })
    .then(data => {
      // Check for sold out days
      if (data.hasDaySlot) {
        setDaySlot(dayButtonLabel);
      }
    });
  }

  const waitlistPostition = () => {
    if (!order.orderReceived) {
      return null;
    }

    ordersService.find({
      query: {
        $limit: 0,
        orderReceived: {
          $lte: order.orderReceived,
        },
        waitlist: true,
      }
    })
    .then(data => {
      setWaitlistOrder(data.total);
    });
  }

  const renderMain = () => {
    let render = null;

    if (order.pickupName) {
      setCurrentStep('Review Order and Submit payment confirmation');

      render = (
        <div>
          <div className="p-6 mx-auto">To complete your order fill out the information below and submit proof of payment.</div>
          <OrderSummary
            order={order}
            price={weeks[0].price}
            description={weeks[0].description}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        </div>
      );
    }
    else if (daySelectionLabel) {
      if (daySlot) {
        setCurrentStep('Tell us about your order');
        render = (
          <div className="height-full">
            <div className="text-orange font-bold text-2xl">We are holding your slot for {daySelectionLabel}, 3pm.</div>
            <OrderInfoForm updateOrder={updateOrder} />
          </div>
        );
      }
      else {
        setCurrentStep('Choose your pickup day');
        render = (
          <div>
            <div className="p-4 border-solid border border-orange2 mb-6 width-full text-center">Your slot is being held for 10 minutes while you complete your transaction</div>
            <div className="height-full">Sorry {daySelectionLabel} just sold out please select another day.</div>
            <Days
              days={days}
              selectDay={selectDay}
              daysUpdateCheck={daysUpdateCheck}
              requestedPieCount={order.pieCount}
            />
          </div>
        );
      }
    }
    else if (order._id) {
      if (hasWeekSlot) {
        setCurrentStep('Choose your pickup day');
        render = (
          <div>
            <div className="p-4 border-solid border border-orange2 mb-6 width-full text-center">Your slot is being held for 10 minutes while you complete your transaction</div>
            <div className="text-center text-orange text-2xl font-bold height-full pb-6">Choose your pickup day</div>
            <Days
              days={days}
              selectDay={selectDay}
              daysUpdateCheck={daysUpdateCheck}
              requestedPieCount={order.pieCount}
            />
          </div>
        );
      }
      else {
        setCurrentStep('Tell us about your order');
        waitlistPostition();
        render = (
          <div>
            <div className="height-full">You are number { waitlistOrder } on the waitlist</div>
            <div className="p-6 mx-auto">To complete your order fill out the information below and submit proof of payment.</div>
            <OrderInfoForm updateOrder={updateOrder} />
          </div>
        );
      }
    }
    else if (weekId) {
      // @todo If we are going to accurately display sold out at this point based on
      // number of pies selected then the ReserveSlot component needs to be
      // refactored to be controlled and set a state in App for us to use here.
      setCurrentStep('Enter instagram name and choose number of pies');
      render = (
        <div>
          { weeks.length > 0 && weeks[0].orders?.length >= weeks[0].maxSlots ?
            (
              <div>
                Explanation that the slots are already full and what happens next.
              </div>
            ) : '' }
          <ReserveSlot
            submitOrder={submitOrder}
            weeks={weeks}
            weeksUpdateCheck={weeksUpdateCheck}
          />
        </div>
      );
    }
    // weekId being false or null have different meanings.
    // null means we haven't received info from the api yet.
    // false means the api returned no results.
    else if (weekId === false) {
      render = (
        <div className="height-full">Sorry there are no pies available this week</div>
      );
    }
    else {
      // Loading or empty waiting for the active week to load.
      render = null;
    }

    return render;
  }

  return (
    <div className="App text-gray-700 text-lg">
      {weeks.length > 0 ?
        <h1 className="pt-6 font-medium mt-1 mx-auto text-blue5 text-center text-3xl md:text-5xl leading-snug">{weeks[0].label}<br />{weeks[0].description}<br />🍫🍫🥧</h1> :
        <h1 className="pt-6 font-medium text-5xl" dangerouslySetInnerHTML={{__html: '&nbsp;'}} />
      }
      <div className="md:grid md:grid-cols-3">
        <Progress currentStep={currentStep} />
        <div className="py-6 sm:col-span-2 lg:col-span-1">{ renderMain() }</div>
        <div className="md:hidden lg:block" />
      </div>
    </div>
  );
}

export default App;
