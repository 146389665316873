import React, {
  useEffect,
  useState,
} from 'react';
import Countdown from 'react-countdown';
import { useForm } from "react-hook-form";

const ReserveSlot = ({ submitOrder, weeks, weeksUpdateCheck }) => {
  const [active, setActive] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    let isActive = false;

    if (weeks.length > 0 && weeks[0].active) {
      isActive = true;
    }

    setActive(isActive);
  }, [weeks, weeksUpdateCheck]);

  const timeComponentFormat = (count, singular, plural, parents) => {
    const parentTotal = parents.reduce((a, b) => a + b, 0);

    if (count > 1) {
      return <div>{count} {plural}</div>;
    }
    else if (count > 0) {
      return <div>{count} {singular}</div>;
    }
    else if (count === 0 && parentTotal > 0) {
      return <div>{count} {plural}</div>;
    }
    else {
      return null;
    }
  }

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <div>
          <div className="text-red">Slots open in</div>
          <div>
            { timeComponentFormat(days, 'day', 'days', []) }
            { timeComponentFormat(hours, 'hour', 'hours', [ days ]) }
            { timeComponentFormat(minutes, 'minute', 'minutes', [ days, hours ]) }
            { timeComponentFormat(seconds, 'second', 'seconds', [ days, hours, minutes ]) }
           </div>
        </div>
      );
    }
  };

  const renderCountdown = () => weeks.length > 0 && weeks[0].launchTimestamp && !active ?
    (
      <div className="block pt-6 font-medium mt-1 mx-auto text-red2 text-2xl text-center">
        <Countdown
          date={weeks[0].launchTimestamp}
          daysInHours={true}
          renderer={renderer}
        />
      </div>
    ) : '';

  return (
    <div>
      { weeks.length > 0 && weeks[0].description ?
        (
          <div className="p-6 text-orange font-bold text-2xl">
            { weeks[0].description } – {weeks[0].price}
          </div>
        ) : '' }
      <form onSubmit={handleSubmit(submitOrder)}>
        <label className="block font-medium mt-1 relative rounded-md p-6 text-pink-dark">
          {errors.ig ?
            <span className="text-red">Instagram username is required</span> :
            <span>Instagram username <span className="text-red" title="required">*</span></span>
          }
          <input
            className="focus:ring-green text-3xl text-green-dark focus:border-green block w-full border-gray-300 rounded-md"
            type="text"
            {...register("ig", { required: true })}
          />
        </label>

        <label className="block font-medium mt-1 relative rounded-md p-6 text-pink-dark">
          How many pies do you want?
          <select
            {...register("pieCount")}
            className="focus:ring-green text-3xl text-green-dark focus:border-green block w-full border-gray-300 rounded-md"
          >
            <option value="1">One</option>
            <option value="2">Two</option>
          </select>
        </label>

        <button
          disabled={!active}
          type="submit"
          className="block rounded-lg mx-auto hover:bg-orange focus:bg-orange bg-orange3 active:bg-orange py-3 px-12 mt-8 transition-transform duration-500 ease-in-out disabled:bg-white2 disabled:opacity-50 disabled:cursor-default transform disabled:translate-y-0 scale-110 disabled:scale-90"
        >
          Next
        </button>
      </form>
      { renderCountdown() }
    </div>
  );
}

export default ReserveSlot;
