import React from 'react';
import { useForm } from "react-hook-form";

const OrderInfoForm = ({ updateOrder }) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  return (
    <form onSubmit={handleSubmit(updateOrder)}>
      <label className="block font-medium mt-1 relative rounded-md p-6 mx-auto text-pink-dark">
        {errors.pickupName ?
          <span className="text-red">Pickup Name is required</span> :
          <span>Pickup Name <span className="text-red" title="required">*</span></span>
        }
        <input
          className="focus:ring-green text-3xl text-green-dark focus:border-green block w-full border-gray-300 rounded-md"
          type="text"
          {...register("pickupName", { required: true })}
        />
      </label>

      <label className="block font-medium mt-1 relative rounded-md p-6 pb-0 mx-auto text-pink-dark">
        <input
          type="checkbox"
          {...register("carpool")}
          className="mr-4 focus:ring-green"
        />
        Do you want to Pie Carpool?
      </label>
      <div className="text-sm px-14 mx-auto">If there are other customers in your area, we will put you in touch with them. You guys will be responsible for organizing your own Pie Carpool, including delivery coordination and fees.</div>

      { watch("carpool") && (
        <label className="block font-medium mt-1 relative rounded-md p-6 mx-auto text-pink-dark">
          {errors.carpoolLocation ?
            <span className="text-red">Carpool Location (city only) is required</span> :
            <span>Carpool Location (city only) <span className="text-red" title="required">*</span></span>
          }
          <input
            className={`focus:ring-green text-3xl text-green-dark focus:border-green block w-full border-gray-300 rounded-md ${errors.carpoolLocation ? 'focus:ring-red focus:border-red' : ''}`}
            type="text"
            {...register("carpoolLocation", { required: true })}
          />
        </label>
      )}

      <label className="block font-medium mt-1 relative rounded-md p-6 mx-auto text-pink-dark">
        Special notes
        <textarea
          className="focus:ring-green text-3xl text-green-dark focus:border-green block w-full border-gray-300 rounded-md resize-none"
          type="text"
          rows="3"

          {...register("notes")}
        />
      </label>

      <label className="block font-medium mt-1 relative rounded-md p-6 pb-0 mx-auto text-pink-dark" style={{textIndent: "-30px", paddingLeft: "57px"}}>
        <input
          type="checkbox"
          {...register("confirmCar", { required: true })}
          className="mr-4 focus:ring-green"
        />
        {errors.confirmCar ?
          <span><span className="text-red">All pies must be picked up via car.</span> Are you willing to send a car for pickup (private or courier is fine)</span>  :
          <span>All pies must be picked up via car. Are you willing to send a car for pickup (private or courier is fine)<span className="text-red" title="required">*</span></span>
        }
      </label>
      <div className="text-sm px-14 mx-auto">We've lost too many pies to motorcycles and it makes us sad 😭</div>

      <button
        type="submit"
        className="block py-3 px-12 rounded-lg mx-auto hover:bg-orange focus:bg-orange bg-orange3 active:bg-orange m-8 scale-110"
      >
        Next
      </button>
    </form>
  );
};

export default OrderInfoForm;
