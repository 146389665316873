/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/solid'

const steps = [
  {
    name: 'Enter instagram name and choose number of pies',
    description: 'This is how we will contact you regarding your pie',
  },
  {
    name: 'Slot held for 10 minutes while you complete your transaction',
    description: 'Your slot is held but hurry up and select your day before your first choice is taken',
    status: 'informational',
  },
  {
    name: 'Choose your pickup day',
    description: 'Options will be removed as they sell out',
  },
  {
    name: 'Tell us about your order',
    description: 'Give us the name you will use to pick up your pie and tell us if you would like help arranging a carpool',
  },
  {
    name: 'Review Order and Submit payment confirmation',
    description: 'Your order is not confirmed until you receive a confirmation message via DM and send a screenshot of your payment',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Progress({ currentStep }) {
  let statusSwtich = 'complete';
  let currentStepText = '';

  return (
    <nav aria-label="Progress" className="p-6 mt-6">
      <ol className="overflow-hidden flex md:items-center md:block">
        {steps.map((step, stepIdx) => {
          let currentStatus = null;
          if (!currentStep) {
            currentStatus = 'upcoming';
          }
          else if (step.name === currentStep) {
            currentStatus = 'current';
            statusSwtich = 'upcoming';

            // Display current step label. Comment out for now since this info should just be in the title. Maybe add the description help text below the title?
            /*currentStepText =
              <span className="ml-4 min-w-0 flex flex-col md:hidden">
                <span className="text-sm font-semibold tracking-wide uppercase">{step.name}</span>
                <span className="text-sm text-gray">{step.description}</span>
              </span>;*/
          }
          else {
            currentStatus = statusSwtich;
          }

          if (step.status && step.status === 'informational') {
            currentStatus = `informational-${currentStatus}`
          }

          // the classNames pr-10 md:pro-0 needs another check needs another check for
          // step.status && step.status === 'informational' then don't add any padding.


          return (
            <li key={step.name} className={classNames(stepIdx !== steps.length - 1 && step?.status !== 'informational' ? 'pr-10' : '', step?.status === 'informational' ? 'hidden': '', 'relative', 'flex-grow', 'md:pb-10')}>
              {currentStatus === 'informational-complete' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div className="-ml-px absolute mt-0.5 top-4 left-4 w-full h-0.5 md:w-0.5 md:h-full  bg-blue-dark" aria-hidden="true" />
                  ) : null}
                  <span className="relative flex items-start group ml-9">
                    <span className="ml-4 min-w-0 hidden md:flex flex-col p-4 border-solid border border-orange2">
                      <span className="text-xs font-semibold tracking-wide uppercase text-blue-dark">{step.name}</span>
                      <span className="text-sm text-blue-dark">{step.description}</span>
                    </span>
                  </span>
                </>
              ) : currentStatus === 'complete' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div className="-ml-px absolute mt-0.5 top-4 left-4 w-full h-0.5 md:w-0.5 md:h-full  bg-blue-dark" aria-hidden="true" />
                  ) : null}
                  <span className="relative flex items-start group">
                    <span className="h-9 flex items-center">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-blue-dark rounded-full group-hover:bg-blue-dark">
                        <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 hidden md:flex flex-col">
                      <span className="text-xs leading-relaxed font-semibold tracking-wide uppercase text-blue-dark">{step.name}</span>
                      <span className="text-sm text-blue-dark">{step.description}</span>
                    </span>
                  </span>
                </>
              ) : currentStatus === 'informational-current' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div className="-ml-px absolute mt-0.5 top-4 left-4 w-full h-0.5 md:w-0.5 md:h-full  bg-gray" aria-hidden="true" />
                  ) : null}
                  <span className="relative flex items-start group" aria-current="step">
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                        <span className="h-2.5 w-2.5 bg-blue-dark rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 hidden md:flex flex-col">
                      <span className="text-sm font-semibold tracking-wide uppercase">{step.name}</span>
                      <span className="text-sm text-gray">{step.description}</span>
                    </span>
                  </span>
                </>
              ) : currentStatus === 'current' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div className="-ml-px absolute mt-0.5 top-4 left-4 w-full h-0.5 md:w-0.5 md:h-full  bg-gray" aria-hidden="true" />
                  ) : null}
                  <span className="relative flex items-start group" aria-current="step">
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                        <span className="h-2.5 w-2.5 bg-blue-dark rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 hidden md:flex flex-col">
                      <span className="text-sm font-semibold tracking-wide uppercase">{step.name}</span>
                      <span className="text-sm text-gray">{step.description}</span>
                    </span>
                  </span>
                </>
              ) : currentStatus === 'informational-upcoming' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div className="-ml-px absolute mt-0.5 top-4 left-4 w-full h-0.5 md:w-0.5 md:h-full  bg-gray" aria-hidden="true" />
                  ) : null}
                  <span className="relative flex items-start group ml-9 hidden md:flex">
                    <span className="ml-4 min-w-0 flex flex-col p-4 border-solid border border-white3">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray">{step.name}</span>
                      <span className="text-sm text-gray">{step.description}</span>
                    </span>
                  </span>
                </>
              ) : (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div className="-ml-px absolute mt-0.5 top-4 left-4 w-full h-0.5 md:w-0.5 md:h-full  bg-gray" aria-hidden="true" />
                  ) : null}
                  <span className="relative flex items-start group">
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray">
                        <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col hidden md:flex">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray">{step.name}</span>
                      <span className="text-sm text-gray">{step.description}</span>
                    </span>
                  </span>
                </>
              )}
            </li>
          );
        })}
      </ol>
      {currentStepText}
    </nav>
  )
}
