import React from 'react';

const DayActive = ({ day, selectDay }) => (
  <button
    onClick={e => selectDay(day._id, day.label)}
    className="day block rounded-lg mx-auto bg-green hover:bg-blue5 focus:bg-blue5 active:bg-blue5 text-white font-bold py-1 px-6"
  >
    {day.label}
  </button>
);

export default DayActive;
